import React, { Component } from 'react';
import dayjs from 'dayjs';
import { QRCodeSVG } from 'qrcode.react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import {
  CustomerList,
  ProductList,
  VehicleList,
} from '@C/CreditCustomers/_utils/';
import services from '@C/CreditCustomers/_utils/data';
import { formatCurrency, TableGrid } from '@C/TableGrid';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';
import { generateCompleteInvoiceCC } from '@C/Utils/ExportUtils';
import { authService } from '@S';

const sortOptions = [
  { value: 'desc', label: 'Date desc' },
  { value: 'asc', label: 'Date asc' },
];

const col = [
  {
    name: 'Date',
    key: 'time_stamp',
    sortable: true,
    type: 'date',
  },
  {
    name: 'Slip',
    key: 'all',
    formatter(props) {
      return <>{props?.slip_no || '--'}</>;
    },
  },
  {
    name: 'Vehicle',
    key: 'vehicle',
    formatter(props) {
      return <>{props?.vehicle_no || '--'}</>;
    },
  },

  {
    name: 'Product',
    key: 'product',
    formatter(props) {
      return <>{props?.name || 'Cash given'}</>;
    },
  },
  {
    name: 'Qty',
    key: 'quantity',
    formatter(props) {
      return <>{props.toFixed(2)}</>;
    },
  },
  {
    name: 'Rate',
    key: 'product',
    formatter(props) {
      return <>{props?.price}</>;
    },
  },
  {
    name: 'Tax',
    key: 'tax',
    formatter(props) {
      return <>{props.tax_group_name}</>;
    },
  },
  {
    name: 'Discount',
    key: 'all',
    type: 'currency',
    formatter(props) {
      return <>{formatCurrency(props.discount)}</>;
    },
  },
  {
    name: 'Amount (Inc Tax)',
    key: 'all',
    type: 'currency',
    formatter(props) {
      return <>{formatCurrency(props.after_tax)}</>;
    },
  },
];

class GenerateInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slipList: [],
      selectedId: '',
      showModal: false,
      staff_id: '',
      start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      end_date: dayjs().format('YYYY-MM-DD'),
      product_id: '',
      credit_customer_id: '',
      gst_no: '',
      slip_no: '',
      vehicle_number: '',
      loading: true,
      limit: 999999999,
      offset: 1,
      count: 0,
      customerList: [],
      balanceList: [],
      productList: [],
      btnLoading: false,
      paginatedLimit: 20,
      sortOrder: 'desc',
      checkedItems: [],
      checkedBalances: [],
      generateProductList: [],
      additionalData: {
        upi_name: authService.currentBunkName,
        upi_id: '',
        notes: '',
        invoice_date: dayjs().format('YYYY-MM-DD'),
        invoice_number: '',
      },
      upiUrl: '',
    };

    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message) => <div className="input-error">{message}</div>,
      validators: {
        upi: {
          // name the rule
          message: 'The :attribute must be a valid UPI address.', // give a message that will display when there is an error. :attribute will be replaced by the name you supply in calling it.
          rule: function (val, params, validator) {
            // return true if it is succeeds and false it if fails validation. the testRegex method is available to give back a true/false for the regex and given value
            // check that it is a valid IP address and is not blacklisted
            return (
              validator.helpers.testRegex(
                val,
                /^[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}$/i,
              ) && params.indexOf(val) === -1
            );
          },
        },
      },
    });
    this.limitInput = React.createRef();
  }

  createInvoice = () => {};

  componentDidMount() {
    this.getCustomerList();
    this.getProductList();
  }

  getProductList = () => {
    services
      .getProductList()
      .then((response) => {
        const tmp = response.data.data;
        const CategoryOptions = [];

        for (let i = 0; i < tmp.length; i++) {
          CategoryOptions.push({
            value: tmp[i].uuid,
            label: tmp[i].product,
            price: tmp[i].price,
            product_type: tmp[i].hasOwnProperty('number_of_units')
              ? 'Lubes'
              : 'Fuel',
            stock: tmp[i].hasOwnProperty('number_of_units')
              ? tmp[i].number_of_units
              : 'Available',
          });
        }
        const isLubes = (element) => element.product_type == 'Lubes';
        CategoryOptions.splice(CategoryOptions.findIndex(isLubes), 0, {
          value: '',
          label: '------',
          isDisabled: true,
        });
        this.setState({ productList: CategoryOptions });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getCustomerList = () => {
    services
      .getCreditCustomerList()
      .then((response) => {
        const tmp = response.data.data.results;
        const CategoryOptions = [];
        for (let i = 0; i < tmp.length; i++) {
          CategoryOptions.push({
            value: tmp[i].uuid,
            label: `${tmp[i].basic_details.first_name} ${tmp[i].basic_details.last_name}`,
          });
        }
        this.setState({ customerList: CategoryOptions });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getCreditSlips = (print) => {
    services
      .getCreditCustomerInvoices(this.state)
      .then((response) => {
        this.setState(
          {
            slipList: response.data.data.results,
            count: response.data.data.count,
            generateProductList: response.data.data.product_list,
            loading: false,
            paginatedList: response.data.data.results,
            balanceList: response.data.data.balances,
          },
          function () {
            this.sortData();
            this.handlePagination(this.state.offset);
            if (print) {
              // promise to wait for pdf to be generated
              let data = this.state.slipList;
              let dataBalance = this.state.balanceList;
              if (this.state.checkedItems.length > 0) {
                // filter date based on slip number in checkedItems
                data = data.filter((item) =>
                  this.state.checkedItems.includes(item.uuid),
                );
              }

              if (this.state.checkedBalances.length > 0) {
                dataBalance = dataBalance.filter((item) =>
                  this.state.checkedBalances.includes(item.uuid),
                );
              }

              new Promise((resolve, reject) => {
                generateCompleteInvoiceCC(
                  data,
                  dataBalance,
                  this.state.additionalData,
                  resolve,
                  reject,
                  this.state.start_date,
                  this.state.end_date,
                  this.state.gst_no,
                );
              })
                .then((response) => {
                  this.setState({ btnLoading: false });
                })
                .catch((error) => {
                  toast.error(error);
                  console.log('There is no data to generate invoice');
                  this.setState({ btnLoading: false });
                });
            }
          },
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleVehicleChange = (e) => {
    this.setState({ vehicle_number: e?.value || '' });
  };

  handleCheckboxChange = (e) => {
    this.setState({ checkedItems: e });
  };

  handleBalanceCheckboxChange = (e) => {
    this.setState({ checkedBalances: e });
  };

  submitForm = (e) => {
    e.preventDefault();
    this.setState({ loading: true }, function () {
      this.getCreditSlips();
    });
  };

  handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'start_date' && dayjs(value).isAfter(this.state.end_date)) {
      this.setState({ [name]: value, end_date: value });
    } else if (
      name === 'end_date' &&
      dayjs(value).isBefore(this.state.start_date)
    ) {
      this.setState({ [name]: value, start_date: value });
    } else {
      this.setState({ [name]: value });
    }
  };

  handleLimitChange = (e) => {
    this.setState({ limit: e.target.value });
  };
  handlePagination = (e) => {
    // custom client side pagination for now
    const list = this.state.slipList;
    const offset = e;
    const limit = this.state.paginatedLimit;
    const start = (offset - 1) * limit;
    const end = offset * limit;
    const paginatedList = list.slice(start, end);
    this.setState({ offset: offset, paginatedList: paginatedList });

    // this.setState({ offset: e }, function() {
    //   this.getCreditSlips();
    // });
  };

  handleBillChange = (e) => {
    this.setState({ slip_no: e.target.value || '' });
  };

  handleProductChange = (e) => {
    this.setState({ product_id: e?.value || '' });
  };

  handleCustomerChange = (e) => {
    this.setState({
      credit_customer_id: e?.value || '',
      gst_no: e.gst_no || '--',
    });
  };

  resetFilter = () => {
    this.setState(
      {
        staff_id: '',
        start_date: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
        end_date: dayjs().format('YYYY-MM-DD'),
        product_id: '',
        additionalData: {
          upi_name: authService.currentBunkName,
          upi_id: '',
          notes: '',
          invoice_date: dayjs().format('YYYY-MM-DD'),
          invoice_number: '',
        },
        credit_customer_id: '',
        vehicle_number: '',
        loading: true,
        slip_number: '',
        btnLoading: false,
      },
      function () {
        this.getCreditSlips();
      },
    );
  };

  generateInvoice = () => {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    this.setState({ btnLoading: true });
    this.generateQRImageURL();
  };

  handleSortOrderChange = (e) => {
    this.setState({ sortOrder: e.value }, function () {
      this.sortData();
    });
  };

  sortData = () => {
    let tmp = this.state.slipList;
    const order = this.state.sortOrder;
    if (order == 'asc') {
      tmp = tmp.sort((a, b) => {
        return new Date(a.time_stamp) - new Date(b.time_stamp);
      });
    }
    if (order == 'desc') {
      tmp = tmp.sort((a, b) => {
        return new Date(b.time_stamp) - new Date(a.time_stamp);
      });
    }

    this.setState({ slipList: tmp }, function () {
      this.handlePagination(this.state.offset);
    });
  };

  handleAdditionalDataChange = (e) => {
    const tmp = this.state.additionalData;
    tmp[e.target.name] = e.target.value;
    this.setState({ additionalData: tmp }, function () {
      this.generateUpiUrl();
    });
  };

  handleResultsToDisplayChange = (e) => {
    this.setState({ paginatedLimit: e.target.value }, function () {
      this.handlePagination(this.state.offset);
    });
  };

  handleQuickSearch = (e) => {
    e.preventDefault();
    if (
      this.limitInput.current.value === '' ||
      this.limitInput.current.value === '0'
    ) {
      return;
    }
    this.setState(
      { paginatedLimit: this.limitInput.current.value, offset: 1 },
      function () {
        this.handlePagination(this.state.offset);
      },
    );
  };

  generateUpiUrl = () => {
    const url = `upi://pay?pa=${this.state.additionalData.upi_id}&pn=${this.state.additionalData.upi_name}&cu=INR`;
    this.setState({
      upiUrl: url,
    });
  };

  generateQRImageURL = () => {
    const tmp = this.state.additionalData;
    const canvas = document.getElementById('upiQR');
    const pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    tmp.upi_qr = pngUrl;
    this.setState({ additionalData: tmp }, function () {
      this.getCreditSlips(true);
    });
  };

  render() {
    const { paginatedList, balanceList } = this.state;
    return (
      <section className="widget full-width list-table inverted">
        <header className="list-header">
          <h3>Generate Invoice</h3>
        </header>
        <main>
          {!this.state.credit_customer_id && (
            <div className="notification-alert notification-alert__error">
              Please select a customer
            </div>
          )}
          <div className="table-filters">
            <div className="filter-wrapper">
              <div className="filter-group">
                <label>Customer:</label>
                {this.validator.message(
                  'customer',
                  this.state.credit_customer_id,
                  'required',
                )}
                <CustomerList
                  onCustomerChange={this.handleCustomerChange}
                  value={this.state.credit_customer_id}
                  options={this.state.customerList}
                />
              </div>
              <div className="filter-group">
                <label>From date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.start_date}
                  name="start_date"
                  onChange={this.handleDateChange}
                />
              </div>
              <div className="filter-group">
                <label>To date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.end_date}
                  min={this.state.start_date}
                  onChange={this.handleDateChange}
                  name="end_date"
                />
              </div>
              <div className="filter-group">
                <label>Product:</label>
                <ProductList
                  onProductChange={this.handleProductChange}
                  value={this.state.product_id}
                  options={this.state.productList}
                />
              </div>
              <div className="filter-group">
                <label> Vehicle:</label>
                <VehicleList
                  handleDropdownChange={this.handleVehicleChange}
                  value={this.state.vehicle_number}
                  selectedCustomerId={this.state.credit_customer_id}
                />
              </div>
              <div className="filter-group input-wrapper">
                <label>Invoice date:</label>
                <input
                  className="cstm-date-input"
                  type="date"
                  value={this.state.additionalData.invoice_date}
                  onChange={this.handleAdditionalDataChange}
                  name="invoice_date"
                />
              </div>
              <div className="filter-group input-wrapper">
                <label>Invoice number </label>
                <input
                  type="text"
                  name="invoice_number"
                  value={this.state.additionalData.invoice_number}
                  onChange={this.handleAdditionalDataChange}
                />
              </div>
              <div className="filter-group input-wrapper">
                <label>UPI Name</label>
                <input
                  type="text"
                  name="upi_name"
                  value={this.state.additionalData.upi_name}
                  onChange={this.handleAdditionalDataChange}
                />
              </div>
              <div className="filter-group input-wrapper">
                <label>UPI ID</label>
                {this.validator.message(
                  'upi',
                  this.state.additionalData.upi_id,
                  'upi',
                )}
                <input
                  type="text"
                  name="upi_id"
                  value={this.state.additionalData.upi_id}
                  onChange={this.handleAdditionalDataChange}
                />
              </div>
              <div className="filter-group">
                <label>Sort order</label>
                <Select
                  name="sort_order"
                  options={sortOptions}
                  onChange={this.handleSortOrderChange}
                  value={sortOptions.filter(
                    ({ value }) => value === this.state.sortOrder,
                  )}
                  defaultValue={{ value: 'desc', label: 'Date desc' }}
                />
              </div>
              <div className="filter-group input-wrapper">
                <label>Notes</label>
                <textarea
                  name="notes"
                  value={this.state.additionalData.notes}
                  onChange={this.handleAdditionalDataChange}
                />
              </div>
              <div
                className="filter-group"
                id="upiQR2"
                style={{ display: 'none' }}
              >
                <QRCodeSVG value={this.state.upiUrl} id="upiQR" />
              </div>
              <div className="filter-group">
                <button
                  className={`btn-with-icon btn-with-icon__pdf blue-btn ${this.state.btnLoading ? 'loading' : ''}`}
                  onClick={this.generateInvoice}
                >
                  Generate Invoice
                </button>
              </div>
              <div className="filter-group reset-filter">
                <button onClick={this.resetFilter}>Reset filters</button>
              </div>
              <div className="filter-group">
                <button
                  className="submit"
                  type="submit"
                  onClick={this.submitForm}
                >
                  Apply filter
                </button>
              </div>
              <div className="filter-group with-icon">
                <label>Results to display</label>
                <form onSubmit={this.handleQuickSearch}>
                  <input
                    type="text"
                    name="paginatedLimit"
                    value={this.state.paginatedLimit}
                    onChange={this.handleResultsToDisplayChange}
                    ref={this.limitInput}
                  />
                  <button
                    type="submit"
                    className="search-icon"
                    onClick={this.handleQuickSearch}
                  />
                </form>
              </div>
            </div>
          </div>
          <div className="table-wrapper">
            <TableGrid
              col={col}
              row={paginatedList || []}
              offset={this.state.offset}
              count={this.state.count}
              limit={this.state.paginatedLimit}
              handlePagination={this.handlePagination}
              hideActions={true}
              enableCheckbox={true}
              checkboxChange={this.handleCheckboxChange}
              enableExport={false}
            />
            {this.state.credit_customer_id && (
              <div className="mini-summary">
                <table>
                  <tbody>
                    {balanceList.map((item, i) => (
                      <tr key={i}>
                        {/* <td style={{ fontWeight: 'bold' }}>{item.opening_date}: {item.opening_balance_on_date.toFixed(2)} </td> */}
                        <td style={{ fontWeight: 'bold', textAlign: 'right' }}>
                          {' '}
                          {item.closing_date}:{' '}
                          {item.closing_balance_on_date.toFixed(2)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <ul>
                  <li>
                    <span>Billed Amount: </span>
                    <span>
                      {new Intl.NumberFormat('en-IN', {
                        style: 'currency',
                        currency: 'INR',
                      }).format(
                        paginatedList?.reduce(
                          (a, b) => a + (b['after_tax'] || 0),
                          0,
                        ) || 0,
                      )}
                    </span>
                  </li>
                  <li>
                    <span>Total discount: </span>
                    <span>
                      {new Intl.NumberFormat('en-IN', {
                        style: 'currency',
                        currency: 'INR',
                      }).format(
                        paginatedList?.reduce(
                          (a, b) => a + (b['discount'] || 0),
                          0,
                        ) || 0,
                      )}
                    </span>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </main>
      </section>
    );
  }
}

export { GenerateInvoice };
