/* eslint-disable new-cap */
import React, { useState, useCallback, useEffect } from 'react';
import services from './data';
import { SMSPackageList } from '@C/Utils';
import { authService } from '@S';
import { authHeader } from '@H';
import axios from 'axios';
import { toast } from 'react-toastify';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';
import { ToggleSwitch } from '@C/FormElements';
import './styles.scoped.scss';

const SmsSetup = () => {
  const [activePackage, setActivePackage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentPackage, setCurrentPackage] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCurrentSMSPackage();
  }, []);

  const getCurrentSMSPackage = () => {
    services.getCurrentSMSPackageList().then((response) => {
      setCurrentPackage(response.data.data.results);
      setLoading(false);
    });
  };

  const handleChange = (e) => {
    setActivePackage(e);
  };

  const submitForm = (e) => {
    e.preventDefault();

    const formData = {
      package_id: activePackage.value,
      bunk_id: authService.currentAccountID,
    };
    const headers = authHeader(1);

    axios
      .post(`${process.env.API_URL}/sms-recharge/`, formData, { headers })
      .then(() => {
        toast.success('Request for SMS package submitted successfully');
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const toggleSMSStatus = (e) => {
    const formData = {
      bunk_id: authService.currentAccountID,
      uuid: e,
    };
    const headers = authHeader(1);

    axios
      .put(`${process.env.API_URL}/sms-recharge/`, formData, { headers })
      .then(() => {
        toast.success('SMS service updated successfully');
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <>
      <section className="widget form-card">
        <header>
          <h3>Request for sms package</h3>
        </header>
        <main className="sub-wrapper">
          <form className="" onSubmit={(e) => submitForm(e)}>
            <div className="input-wrapper with-label">
              <label> Request for SMS (Select Package) </label>
              <SMSPackageList
                handleSMSChange={handleChange}
                value={activePackage}
              />
            </div>
            <div className="input-wrapper with-label">
              <label> Amount to pay </label>
              <input
                type="text"
                value={activePackage.amount}
                name="amount"
                disabled
              />
            </div>
            <div className="form-actions">
              <button className="submit" type="submit" disabled={isSubmitting}>
                Submit
              </button>
            </div>
          </form>
        </main>
      </section>
      <section className="widget list-card list-card__minimal">
        <header>
          <h3>Active plan</h3>
        </header>
        <main className="sub-wrapper form-info">
          {(() => {
            if (loading) {
              return <DropLoaderLarge />;
            } else if (currentPackage.length === 0) {
              return (
                <div className="form-item">
                  <label> Activate Plan </label>
                  <span>No active plan</span>
                </div>
              );
            } else {
              return (
                <>
                  <div className="form-item">
                    <label> Activate Plan </label>
                    <span>{currentPackage[0]?.package.name}</span>
                  </div>
                  <div className="form-item">
                    <label> SMS </label>
                    <span>
                      {currentPackage[0]?.remaining_sms_count +
                        '/' +
                        currentPackage[0]?.package.sms}
                    </span>
                  </div>
                  <div className="form-item">
                    <label>SMS Status</label>
                    <span>
                      {currentPackage[0]?.is_active ? 'Active' : 'Inactive'}
                    </span>
                    {/* <ToggleSwitch
                      id="sms_status"
                      name="sms_status"
                      optionLabels={['Active', 'Deacivate']}
                      checked={currentPackage[0].is_active}
                      onChange={(e) => toggleSMSStatus(currentPackage[0].uuid)}
                    /> */}
                  </div>
                </>
              );
            }
          })()}
        </main>
      </section>
    </>
  );
};

export { SmsSetup };
